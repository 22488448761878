<template>
  <div class="flex h-[calc(100vh_-_4rem_*_2_-_1.5rem_*_2)] items-center justify-center">
    <div class="flex flex-col items-center justify-center gap-2 text-red-800">
      <Icon name="exclamation-mark" />
      <span>Ошибка</span>
    </div>
  </div>
</template>

<script setup></script>
